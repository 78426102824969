import { memo as Memo, useMemo, useState, useRef, useEffect } from 'react'

//* HOC's
import { withDataContext, withLanguageContext } from 'context'

import { Page, AnimationWrapp } from 'components/common'
import {
    AboutInvest,
    WhyArmenia,
    InvestFields,
    DoneWorks,
    CatalogView,
    NewsInHome,
    OurServices,
    PartnersSlider,
    BottomBlock,
    Testimonials,
} from 'components/pages'

//* Helpers
import { config, arrayGenChild } from 'helpers'

const Home = Memo(({ pages, global, pageParams, selectedLang, preloader, ...props }) => {
    //! Page Data
    const pageData = useMemo(() => pages?.[pageParams.name]?.[selectedLang], [pages, selectedLang, pageParams.name])

    //! State
    const [secObj, setSecObj] = useState()
    const [numberObj, setNumberObj] = useState()

    //! Refs
    const Ref1 = useRef(),
        Ref2 = useRef(),
        Ref3 = useRef(),
        Ref4 = useRef(),
        Ref5 = useRef(),
        Ref6 = useRef(),
        Ref7 = useRef(),
        Ref8 = useRef(),
        Ref9 = useRef(),
        Ref10 = useRef(),
        Ref11 = useRef()

    useEffect(() => {
        if (pageData) {
            if (
                pageData &&
                Ref1?.current?.[2] &&
                Ref1?.current?.[3] &&
                Ref2?.current?.[1] &&
                Ref2?.current?.[2] &&
                Ref3?.current?.[1] &&
                Ref4?.current?.[1] &&
                Ref4?.current?.[2] &&
                Ref4?.current?.[3] &&
                Ref5?.current?.[1] &&
                Ref5?.current?.[2] &&
                Ref7?.current?.[1] &&
                Ref8?.current?.[2] &&
                Ref9?.current &&
                Ref10?.current[1] &&
                Ref11?.current[1] &&
                !preloader
            ) {
                setSecObj([
                    arrayGenChild(Ref1.current[0][0].childNodes),
                    arrayGenChild(Ref1.current[1][0].childNodes),
                    Ref1.current[2].current,
                    Ref1.current[3].current,
                    arrayGenChild(Ref2.current[0][0].childNodes),
                    Ref2.current[1].current,
                    Ref2.current[2],
                    arrayGenChild(Ref3.current[0][0].childNodes),
                    Ref3.current[1],
                    arrayGenChild(Ref4.current[0][0].childNodes),
                    Ref4.current[1].current,
                    Ref4.current[2].current,
                    Ref4.current[3],
                    arrayGenChild(Ref5.current[0][0].childNodes),
                    Ref5.current[1].current,
                    Ref5.current[2],
                    arrayGenChild(Ref6.current[0][0].childNodes),
                    arrayGenChild(Ref7.current[0][0].childNodes),
                    Ref7.current[1].current,
                    arrayGenChild(Ref8.current[0][0].childNodes),
                    arrayGenChild(Ref8.current[1][0].childNodes),
                    Ref8.current[2].current,
                    Ref8.current[3].current,
                    arrayGenChild(Ref10.current[0].current.childNodes[0].childNodes),
                    Ref10.current[1].current.childNodes[1],
                    Ref10.current[2].current.childNodes,
                    Ref10.current[3].current,
                    arrayGenChild(Ref11.current[0].current.childNodes[0].childNodes),
                    Ref11.current[1].current.childNodes[1],
                    Ref11.current[2].current.childNodes,
                    Ref11.current[3].current,
                ])
                setNumberObj([Ref9.current[0], Ref9.current[1].current, Ref9.current[2].current])
            }
        }
    }, [Ref1.current, Ref2.current, Ref3.current, Ref4.current, Ref5.current, Ref7.current, Ref8.current, pageData, selectedLang, preloader])

    return (
        <Page
            className='home'
            meta={{
                title: pageData?.content?.meta?.title,
                description: pageData?.content?.meta.description,
                keywords: pageData?.content?.meta.keywords,
                image: pageData?.content.meta_image,
            }}
        >
            {pageData && (
                <AnimationWrapp
                    state={secObj}
                    countAnimation={numberObj}
                    titlesItem={[
                        pageData?.content.section_4_field_1_title,
                        pageData?.content.section_4_field_2_title,
                        pageData?.content.section_4_field_3_title,
                    ]}
                    onDelay={3}
                >
                    <AboutInvest
                        ref={Ref1}
                        title={pageData?.content.section_1_title}
                        description={pageData?.content.section_1_subtitle}
                        url={config.routes.ourServices.path}
                        imgTop={pageData.content.section_1_images[0]}
                        imgLeft={pageData.content.section_1_images[1]}
                        imgRight={pageData.content.section_1_images[2]}
                    />

                    <WhyArmenia
                        title={pageData?.content.section_2_title}
                        url={config.routes.businessInArmenia.path}
                        whyArm1={pageData?.content.section_2_field_1}
                        whyArm2={pageData?.content.section_2_field_2}
                        whyArm3={pageData?.content.section_2_field_3}
                        whyArm4={pageData?.content.section_2_field_4}
                        ref={Ref2}
                    />

                    <InvestFields
                        title={pageData?.content.section_3_title}
                        field1={pageData?.content.section_3_field_1}
                        field2={pageData?.content.section_3_field_2}
                        field3={pageData?.content.section_3_field_3}
                        field4={pageData?.content.section_3_field_4}
                        field5={pageData?.content.section_3_field_5}
                        field6={pageData?.content.section_3_field_6}
                        ref={Ref3}
                    />

                    <DoneWorks
                        bgImg={pageData?.content.section_4_image}
                        work1Title={pageData?.content.section_4_field_1_title}
                        work1SubTitle={pageData?.content.section_4_field_1_subtitle}
                        work2Title={pageData?.content.section_4_field_2_title}
                        work2SubTitle={pageData?.content.section_4_field_2_subtitle}
                        work3Title={pageData?.content.section_4_field_3_title}
                        work3SubTitle={pageData?.content.section_4_field_3_subtitle}
                        ref={Ref9}
                    />
                    <CatalogView
                        ref={Ref10}
                        inProductSubmission={false}
                        singleInvest={true}
                        notLast={true}
                        title={pageData?.content.section_5_title}
                        catalogsArr={pageData?.projects}
                        homeText={pageData?.content.section_5_subtitle}
                        url={config.routes.investmentProjects.path}
                    />

                    <CatalogView
                        ref={Ref11}
                        inProductSubmission={false}
                        notLast={true}
                        product={true}
                        singleInvest={true}
                        title={pageData?.content.section_6_title}
                        catalogsArr={pageData?.products}
                        homeText={pageData?.content.section_6_subtitle}
                        url={config.routes.exportFromArmenia.path}
                    />

                    <NewsInHome
                        ref={Ref4}
                        url={config.routes.news.path}
                        news={pageData?.posts}
                        title={pageData?.content.section_7_title}
                        description={pageData?.content.section_7_subtitle}
                    />

                    <OurServices
                        url={config.routes.ourServices.path}
                        title={pageData?.content.section_8_title}
                        service1={pageData?.content.section_8_service_1}
                        service2={pageData?.content.section_8_service_2}
                        service3={pageData?.content.section_8_service_3}
                        service4={pageData?.content.section_8_service_4}
                        ref={Ref5}
                    />

                    <Testimonials testimonials={pageData?.testimonials} ref={Ref6} />

                    <PartnersSlider url={config.routes.becomePartner.path} logosArr={pageData?.partners} inHome={true} ref={Ref7} />

                    <BottomBlock ref={Ref8} />
                </AnimationWrapp>
            )}
        </Page>
    )
})

export default withLanguageContext(withDataContext(Home, ['pages', 'global']), ['selectedLang'])
